// React import
import React, { useState } from "react";

// MUI, RSUITE, SLIDEr imports
import Container from "@mui/material/Container";
import { Row } from "rsuite";
import Slider from "react-slick";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Panel, PanelGroup } from "rsuite";

// style page import
import "./landingPage.css";

// image imports from local

// import moduleImage from "../../assets/images/exe-cource-tumb.jpg";

// import sampleImage from "../../assets/images/offerdummy.jpg";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

// image imports from react-icons
import { IoLocationOutline } from "react-icons/io5";
import { IoMdPhonePortrait } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { SlSocialFacebook } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import config from "../../config/appConfig";

const LandingPage = () => {

	const theme: any = useTheme();
	// const [ activeIndex, setActiveIndex ] = useState(1);
	const [ activeSlide, setActiveSlide ] = useState<any>(0);

	const indoSoulLogo = `${config.appDomain}/${config?.indoSoulImageKey}indosoullogo.png`;
	const Module1 = `${config.appDomain}/${config?.landingImageKey}Modules1.jpeg`;
	const Module2 = `${config.appDomain}/${config?.landingImageKey}Modules2.jpeg`;
	const Module3 = `${config.appDomain}/${config?.landingImageKey}Modules3.jpeg`;
	const Module4 = `${config.appDomain}/${config?.landingImageKey}Modules4.jpeg`;
	const Module5 = `${config.appDomain}/${config?.landingImageKey}Modules5.jpeg`;
	const Learn = `${config.appDomain}/${config?.landingImageKey}classical-music-made-simple.svg`;
	const Exclusive = `${config.appDomain}/${config?.landingImageKey}module-based-step-by-step-learning.svg`;
	const Song = `${config.appDomain}/${config?.landingImageKey}track-your-progress.svg`;
	const Customized = `${config.appDomain}/${config?.landingImageKey}professional-play-alongs.svg`;
	const Personalized = `${config.appDomain}/${config?.landingImageKey}periodic-online-meets-with-teachers.svg`;
	const CollageImage = `${config.appDomain}/${config?.landingImageKey}collaged-image.jpeg`;
	// const icon = `${config.appDomain}/${config?.landingImageKey}soulswara-primary-A.png`;

	const navigate = useNavigate();

	const modulesData = [
		{
			"moduleName": "Orientation – Your Violin Journey Starts Here",
			"moduleIcon": "./frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module1,
			"author"    : "Karthik"
		},
		{
			"moduleName": "Bowing and The First String",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module2,
			"author"    : "Karthik"
		},
		{
			"moduleName": "The Second-String Joins In",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module3,
			"author"    : "Karthik"
		},
		{
			"moduleName": "Kangal Irandal",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module4,
			"author"    : "Karthik"
		},
		{
			"moduleName": "Rama Janardhana",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module5,
			"author"    : "Karthik"
		}
	];

	const [ activeTab, setActiveTab ] = useState(0);

	const handleTabClick = (index: any) => {

		setActiveTab(index);

	};


	// const scrollIntoView = () => {

	// 	const elmntToView: any = document.getElementById("shLandingPageVideoSection");

	// 	elmntToView?.scrollIntoView();

	// };

	const PrevArrow = (props: any) => {

		const { className, style, onClick } = props;

		return (
			<div className={className} style={{ ...style, display: "block", color: parseFloat(activeSlide) == 0 ? theme.palette?.grey[800] : theme.palette.text.primary }} onClick={onClick}>
				{/* &lt; */}
				<SlArrowLeft className="sh-landing-page-left-arrow" />
			</div>
		);

	};

	const NextArrow = (props: any) => {

		const { className, style, onClick } = props;

		return (
			<div className={className} style={{ ...style, display: "block", color: window?.innerWidth > 400 ? parseFloat(activeSlide) + 3 == modulesData?.length ? theme.palette?.grey[800] : theme.palette.text.primary : parseFloat(activeSlide) == modulesData?.length - 1 ? theme.palette?.grey[800] : theme.palette.text.primary }} onClick={onClick}>
				{/* &gt; */}
				<SlArrowRight className="sh-landing-page-right-arrow" />
			</div>
		);

	};
	

	const settings = {
		dots          : false,
		infinite      : false,
		speed         : 500,
		slidesToShow  : 3,
		slidesToScroll: 1,
		className     : "center",
		// slideSpacing  : 20,
		
		/*
		 *  centerMode: true,
		 *  centerPadding: "20px",
		 */
		 
		nextArrow: <NextArrow
			className={"sh-landing-page-next-arrow"}
			style={{ color: theme.palette.text.primary }}
		/>
		,
		prevArrow: <PrevArrow
			className={"sh-landing-page-prev-arrow"}
			style={{ color: theme.palette.text.primary }}
		/>,
		afterChange: (current: any) => setActiveSlide(current),
		responsive : [
			{
				breakpoint: 1200,
				settings  : {
					slidesToShow  : 2,
					slidesToScroll: 1,
					infinite      : false
				}
			},
			{
				breakpoint: 600,
				settings  : {
					slidesToShow  : 1,
					slidesToScroll: 1,
					infinite      : false
				}
			}

		]

	};

	return (
		<div className="landing-page">
			<div className="landing-page-full-height-con mobile-view-landing-page-full-height-con landing-page-section-1-con">
				<Container>
					<Row gutter={0} className="justify-content-md-center sh-landing-page-section-1-row-con">
						<div className="sh-landing-page-level-up-line" style={{ color: theme.palette.text.primary, paddingTop: "3em" }}>Start your violin journey <br className="sh-line-breaker-to-hide-mobile" />  with Soul Swara! </div>
						<div className="sh-landing-page-your-boss-line" style={{ color: theme.palette.text.primary }}>Unlock your music potential at your own pace.<br /> <span style={{ color: theme?.palette?.primary?.main }}>You are just one click away!</span></div>
						{/* <div className="sh-landing-page-tagline-title" style={{
							color: theme.palette.text.primary
						}}>Get <strong>everything</strong> you need to Soul Swara, all in one place</div> */}
						<button className="sh-landing-page-join-us-button" onClick={() => navigate(config?.routes?.login)} style={{ color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.main }}>
							<a onClick={() => navigate(config?.routes?.login)} style={{ color: theme.palette.text.primary }}>
								Let&apos;s get started <span></span>
							</a>
						</button>
						{/* <Lottie onClick={scrollIntoView} animationData={arrowDown} className="sh-landing-page-lottie-down-arrow" /> */}
					</Row>
				</Container>
			</div>
			<div className="landing-page-combined-sec-2-3-background-image">
				<div id="shLandingPageVideoSection" className="landing-page-full-height-con landing-page-section-2-con">
					<Row className="justify-content-md-center sh-landing-page-section-2-row-con">
						<div className="sh-landing-page-section-2-black-background">
							{/* <div className="sh-landing-page-section-2-top-white-background"></div> */}
							<Container style={{ padding: "0px" }}>
								<video className="sh-landing-page-video-con" controls controlsList="nodownload noremoteplayback noplaybackrate" autoPlay muted loop>
									<source src={`${config.appDomain}/${config?.teaserVideo}`} type="video/mp4" />
									Your browser does not support the video.
								</video>
							</Container>
						</div>
					</Row>
				</div>
				<div className="landing-page-full-height-con landing-page-section-3-con">
					<Container style={{ padding: "0px" }}>
						<Row className="justify-content-md-center sh-landing-page-section-3-row-con">
							<div className="sh-landing-page-section-2-text-con" style={{ color: theme.palette.secondary.main }}>Start your Violin Journey with a FREE TRIAL!</div>
							<button className="sh-landing-page-join-us-free-trial-button" onClick={() => navigate(config?.routes?.login)} style={{ color: theme.palette.text.primary, backgroundColor: theme?.palette?.primary?.main }}>
								<a className="sh-landing-page-lets-gets-btn-link-tag" onClick={() => navigate(config?.routes?.login)} href="">
									Join us with a FREE TRIAL <span></span>
								</a>
							</button>
						</Row>
					</Container>
				</div>
			</div>
			<div className="landing-page-full-height-con landing-page-section-4-con" style={{ backgroundColor: theme.palette.background.default }}>
				<div style={{ padding: "0px" }}>
					<Container>
						<Row className="justify-content-md-center sh-landing-page-section-4-row-con">
							<Typography variant="h1" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-section-4-title">Featured Courses</Typography>
							<div className="slider-container" >
								<Slider {...settings}>
									{
										modulesData.map((module, index) => {

											return (
												<div key={index} className="module-card" onClick={() => { navigate(config?.routes?.course);}}>
													<div className="module-card-image-con" >
														<img src={module.image} alt={module.moduleName} className="module-card-image" />
													</div>
													<div className="module-card-title">
														<div>
															<div style={{ color: theme.palette.text.primary }} className="module-card-title-content">{module.moduleName}</div>
															{/* <div style={{ color: theme.palette.secondary.main }}>{module?.author}</div> */}
														</div>
													</div>
												</div>
											);

										})
									}
								</Slider>
							</div>
						</Row>
					</Container>
				</div>
			</div>
			<div className="landing-page-full-height-con landing-page-section-5-con landing-page-section-4-con-desktop-view" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-5-row-con">
						<Typography variant="h1" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-section-4-title">What do we offer?</Typography>
						<div className="vertical-tabs">
							<div className="tab-list">
								{/* {tabs.map((tab, index) =>
									<div
										key={index}
										className={`tab-item ${index === activeTab ? "active" : ""}`}
										onClick={() => handleTabClick(index)}
									>
										{tab.label}
									</div>
								)} */}
								<div className={"tab-item"} onClick={() => handleTabClick(0)}>
									<div className="tab-item-inner-con">
										{/* <FontAwesomeIcon style={{ color: theme.palette.secondary.main }} icon={faMusic} /> */}
										<span style={{ color: activeTab === 0 ? theme?.palette?.primary?.main : theme.palette.text.primary }}> EXCLUSIVE LEARNING MODULES	</span>
									</div>
									{/* {activeTab === 0 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(1)}>
									<div className="tab-item-inner-con">
										{/* <BsBarChartSteps color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 1 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>CHOOSE WHAT YOU WANT TO LEARN </span>
									</div>
									{/* {activeTab === 1 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(2)}>
									<div className="tab-item-inner-con">
										{/* <GiProgression color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 2 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>SONG LED LEARNING</span>
									</div>
									{/* {activeTab === 2 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(3)}>
									<div className="tab-item-inner-con">
										{/* <FiPlay color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 3 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>CUSTOMIZED Q&A SESSION WITH VIOLIN EXPERTS</span>
									</div>
									{/* {activeTab === 3 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(4)}>
									<div className="tab-item-inner-con">
										{/* <GiTeacher color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 4 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>PERSONALIZED FEEDBACK</span>
									</div>
									{/* {activeTab === 4 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
							</div>
							<div className="tab-content">
								{
									activeTab === 0 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}> Exclusive learning modules</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Learn from our bite sized module-based courses where you learn a new concept  even if you spend only 10 minutes at a time. Every module is exclusively curated for effective and holistic learning.</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Learn} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 1 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Choose what you want to learn</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Violin learning has never been this customised! Choose from a menu of module based learning, songs and skills to pick what you want to learn. Once done, you can come back to choose an allied song/skill or proceed further to the next module.</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Exclusive} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 2 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Song led learning</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Learn a song at the conclusion of each module. This song is curated to meet the
														technical abilities that you have uncovered in that module. Apart from this, explore
														and choose from the variety of songs that are present in the “Songs and Skills”
														section.</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Song} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 3 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Customized Q&A session with violin experts</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">We believe in regular touchpoints during the self-paced courses to help answer your
														questions as well as fine tune any inaccuracies in your playing. These come in the
														form of periodic online video sessions. Get in touch with us at <a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a> to book one.</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Customized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 4 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Personalized feedback </div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Magic happens when you practice and have a ton of fun playing  the violin. Join our Whatsapp community where you get to go on a journey with fellow violin learners, have a constant feedback loop with our expert teachers and track your practice.</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Personalized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</Row>
				</Container>
			</div>
			<div className="landing-page-full-height-con landing-page-section-5-con landing-page-section-4-con-mobile-view" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-5-row-con">
						<Typography variant="h1" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-section-4-title sh-landing-page-section-4-title-mobile">What do we offer?</Typography>
						<PanelGroup accordion>
							<Panel bordered={true} className="sh-accordian-indivial-panel-con" header="Exclusive learning modules" eventKey={0}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										{/* <div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Learn What you want to learn</div> */}
										<div className="landing-page-section-5-content-side-content-para">Learn from our bite sized module-based courses where you learn a new concept  even if you spend only 10 minutes at a time. Every module is exclusively curated for  effective and holistic learning.</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Learn} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel bordered={true} className="sh-accordian-indivial-panel-con" header="Choose what you want to learn" eventKey={1}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										{/* <div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Exclusive Learning Modules</div> */}
										<div className="landing-page-section-5-content-side-content-para">Violin learning has never been this customised! Choose from a menu of module - based learning, songs and skills to pick what you want to learn. Once done, you can come back to choose an allied song/skill or proceed further to the next module.</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Exclusive} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel bordered={true} className="sh-accordian-indivial-panel-con" header="Song led learning" eventKey={2}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										{/* <div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Song Oriented Learning</div> */}
										<div className="landing-page-section-5-content-side-content-para">Learn a song at the conclusion of each module. This song is curated to meet the technical abilities that you have uncovered in that module. Apart from this, explore and choose from the variety of songs that are present in the “Songs and Skills”  section.</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Song} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel bordered={true} className="sh-accordian-indivial-panel-con" header="Customized Q&A session with violin experts" eventKey={3}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										{/* <div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Customized QnA Session with Violin Experts</div> */}
										<div className="landing-page-section-5-content-side-content-para">We believe in regular touchpoints during the self-paced courses to help answer your questions as well as fine tune any inaccuracies in your playing. These come in the  form of periodic online video sessions. Get in touch with us at <a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a> to book one.</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Customized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel bordered={true} className="sh-accordian-indivial-panel-con" header="Personalized feedback" eventKey={4}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										{/* <div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Personalized Feedback and Practice Forums</div> */}
										<div className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Personalized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
						</PanelGroup>
					</Row>
				</Container>
			</div>
			<div className="landing-page-full-height-con landing-page-section-7-con" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-7-row-con">
						<Typography variant="h1" style={{ color: theme.palette.text.primary, textAlign: "center" }} className="sh-landing-page-section-4-title">Online Indian Classical Music Education Like Never Before</Typography>
						<div className="sh-landing-page-online-indian-section-image">
							<img className="sh-landing-page-collage-image" src={CollageImage} alt="" />
						</div>
					</Row>
				</Container>
			</div>
			<div className="landing-page-section-6-con" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-6-row-con">
						<Typography variant="h1" style={{ color: theme.palette.secondary.main, textAlign: "center" }} className="sh-landing-page-section-4-title">FAQ</Typography>
						<PanelGroup className="sh-accordian-content-faq-con" accordion bordered={false}>
							<div>
								<Panel
									bordered={false}
									className="" header="I am already learning from a guru. How would the courses help me?" eventKey={0}>
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-6-content-side-content-para">You can use these courses to add value to your playing as you continue your existing learning. With the module-based learning, you learn customized and exclusive exercises that carry best practices from Indian and Western classical teaching systems. These will help you to improve your sounding and confidence in playing. With the Songs and Skills, you can pick and choose specific songs or techniques that you would like to take deep dive into. </div>
										</div>
									</div>
								</Panel>
							</div>
							<div>
								<Panel
									bordered={false}
									className="" header="How is it different from online classes?" eventKey={1}>
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-6-content-side-content-para">The self-paced courses are mainly aimed at busy students or professionals who have demanding time schedules. Hence the pace of the learning can be as slow or fast as you would like it to be. The byte sized learning modules also help to cover ground irrespective of the limited time you might have on a given day. These courses are also aimed at students who wish to upskill their violin playing with specific techniques and songs. In addition to the self-paced courses, you also have the option to attend periodic Q&A sessions with our teachers to help answer questions and fine tune your playing. Unlike online classes, workout videos offer you unlimited chances to practice exercises along with Karthick Iyer in a call and response format.</div>
										</div>
									</div>
								</Panel>
							</div>
							<div>
								<Panel
									bordered={false}
									className="" header="Who can enroll for these courses?" eventKey={2}>
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-6-content-side-content-para">Violinists, both Carnatic and Western, of all levels including absolute beginners can enroll for the courses available. Absolute beginners are recommended to start with our “Module based learning” that starts violin education from the very foundational beginnings.</div>
										</div>
									</div>
								</Panel>
							</div>
							<div>
								<Panel
									bordered={false}
									className="" header="What shruti are the exercises in?" eventKey={3}>
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-6-content-side-content-para">All videos under the Module based learning are in the Carnatic Shruti G (5 kattai). The videos in Songs and Skills section vary in pitch based on type of song/skill. Most of the videos will be in D(2 kattai) or G(5 kattai).</div>
										</div>
									</div>
								</Panel>
							</div>
							<div>
								<Panel
									bordered={false}
									className="" header=" What are the requirements to attend these courses?" eventKey={4}>
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-6-content-side-content-para">You would require a working violin, bow and decent wifi/mobile internet connection to get started with our courses. If you have any questions on violin purchase reach out to us by call/email.</div>
										</div>
									</div>
								</Panel>
							</div>
						</PanelGroup>
					</Row>
				</Container>
			</div>
			<footer className="sh-landing-page-footer-container" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<div>
						<div>
							<div>
								<div className="sh-landing-page-footer-section-1-con">
									<div className="sh-landing-page-footer-section-1-column-1">
										<div>
											<div>
												<img className="sh-landing-page-logo-icon" src={indoSoulLogo} alt="" />
											</div>
											<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-about-text" style={{ color: theme.palette.text.primary }}>
												SOUL SWARA – an offering of Indosoul  Academy of Music
											</Typography>
										</div>
									</div>
									<div className="sh-landing-page-footer-section-1-column-2">
										<div>
											<Typography variant="h5" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-head-content sh-landing-page-get-in-touch-text">
												Get in touch
											</Typography>
											<div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-location-icon" src={location} alt="" /> */}
														<IoLocationOutline style={{ color: theme?.palette?.primary?.main }} className="sh-landing-page-footer-icon sh-landing-page-location-icon" />
													</div>
													<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-loation-text" style={{ color: theme.palette.text.primary }}>
														3B, Nataraja Nilayam, Kalakshetra Avenue, 2nd Street, Chennai, Tamil Nadu, 600041
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-phone-icon" src={smartphone} alt="" /> */}
														<IoMdPhonePortrait style={{ color: theme?.palette?.primary?.main }} className="sh-landing-page-footer-icon sh-landing-page-phone-icon" />
													</div>
													<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-phone-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-phone-cta" href="tel:+91 8850411961">+91 8850411961</a>
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-email-icon" src={mail} alt="" /> */}
														<IoMailOutline style={{ color: theme?.palette?.primary?.main }} className="sh-landing-page-footer-icon sh-landing-page-email-icon" />
													</div>
													<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-email-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-mail-to-cta" href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>
													</Typography>
												</div>
											</div>
										</div>
									</div>
									<div className="sh-landing-page-footer-section-1-column-3">
										<div>
											<div>
												<Typography variant="h5" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-head-content sh-landing-page-follow-us-text">
													Social Media
												</Typography>
											</div>
											<div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-facebook-icon" src={facebook} alt="" /> */}
														<SlSocialFacebook
															onClick={() => window.open("https://www.facebook.com/profile.php?id=100095242332182", "_blank")}
															className="sh-landing-page-footer-icon sh-landing-page-facebook-icon" />
													</div>
													<Typography variant="h5"
														onClick={() => window.open("https://www.facebook.com/profile.php?id=100095242332182", "_blank")}
														className="sh-landing-page-text-content sh-landing-page-facebook-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-facebook">Facebook</a>
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-youtube-icon" src={youtube} alt="" /> */}
														<AiOutlineYoutube
															onClick={() => window.open("https://www.youtube.com/@indosoul_academy", "_blank")}
															className="sh-landing-page-footer-icon sh-landing-page-youtube-icon" />
													</div>
													<Typography variant="h5"
														onClick={() => window.open("https://www.youtube.com/@indosoul_academy", "_blank")}
														className="sh-landing-page-text-content sh-landing-page-youtube-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-youtube">Youtube</a>
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-instagram-icon" src={instagram} alt="" /> */}

														<FaInstagram
															onClick={() => window.open("https://www.instagram.com/indosoul_academy/", "_blank")}
															className="sh-landing-page-footer-icon sh-landing-page-instagram-icon" />
													</div>
													<Typography variant="h5"
														onClick={() => window.open("https://www.instagram.com/indosoul_academy/", "_blank")}
														
														className="sh-landing-page-text-content sh-landing-page-instagram-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-instagram">Instagram</a>
													</Typography>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<hr style={{ color: theme.palette.text.primary }} />
						</div>
						<div className="sh-landing-page-footer-copyright-text">
							<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-loation-text" style={{ color: theme.palette.text.primary }}>
								<span> Copyright © 2024 Soul Swara. </span>&nbsp;&nbsp;|&nbsp;&nbsp;
								<span
									onClick={() => window.open(config.routes?.termsAndConditions, "_blank")}
									className="footer-content-link" >Terms and Conditions</span>&nbsp;&nbsp;|&nbsp;&nbsp;
								<span className="footer-content-link" onClick={() => window.open(config.routes?.privacyPolicy, "_blank")}>Privacy Policy</span>&nbsp;&nbsp;|&nbsp;&nbsp;
								<span onClick={() => window.open(config.routes?.refundPolicy, "_blank")} className="footer-content-link">Refund Policy</span>
								<span style={{ float: "right" }}>presented by VIOLIN TECHNIQUE WITH KARTHICK IYER</span>
							</Typography>
						</div>
					</div>
				</Container>
			</footer>
		</div>
	);

};

export default LandingPage;
